import React from "react";

const CarAlert = ({ height = "49", width = "71", fill }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={width}
    height={height}
    viewBox="0 0 71 49"
  >
    <defs>
      <clipPath id="clip-car">
        <rect width="71" height="49" />
      </clipPath>
    </defs>
    <g id="car" clipPath="url(#clip-car)">
      <g id="Grupo_1" transform="translate(1 1)">
        <g id="Grupo_2425" transform="translate(0)">
          <g id="Grupo_2268">
            <path
              id="Caminho_3113"
              d="M3394.886,2079.4l-2.615,2.4a5.645,5.645,0,0,1-3.811,1.477l-7.012-.009-9.309.009a5.627,5.627,0,0,1-3.81-1.477l-2.62-2.4"
              transform="translate(-3345.787 -2061.509)"
              fill="none"
              stroke="#2e323c"
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="1.3"
            />
            <path
              id="Caminho_3114"
              d="M3389.573,2090.652h-10.681v-1.881a3.808,3.808,0,0,1,3.257-3.764l7.423-1.023"
              transform="translate(-3330.481 -2056.186)"
              fill="none"
              stroke="#2e323c"
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="1.3"
            />
            <path
              id="Caminho_3115"
              d="M3360.774,2083.983l7.426,1.023a3.808,3.808,0,0,1,3.258,3.764v1.881h-10.683"
              transform="translate(-3351.52 -2056.186)"
              fill="none"
              stroke="#2e323c"
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="1.3"
            />
            <line
              id="Linha_35"
              y2="17.706"
              transform="translate(3.499 23.263)"
              fill="none"
              stroke="#2e323c"
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="1.3"
            />
            <path
              id="Caminho_3116"
              d="M3359.991,2094.384l-2.948-3.2a1.721,1.721,0,0,1-.551-1.26v-3.491a1.723,1.723,0,0,1,1.723-1.723h6.727l5.823-11.165a6.116,6.116,0,0,1,4.87-2.421H3405.7a6.11,6.11,0,0,1,4.869,2.421l5.821,11.165h6.73a1.724,1.724,0,0,1,1.722,1.723v3.491a1.72,1.72,0,0,1-.549,1.26l-2.951,3.2v17.708"
              transform="translate(-3356.492 -2071.122)"
              fill="none"
              stroke="#2e323c"
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="1.3"
            />
            <path
              id="Caminho_3117"
              d="M3381.5,2090.078v.66a5.4,5.4,0,0,0,5.4,5.4h0a5.4,5.4,0,0,0,5.4-5.4v-.66"
              transform="translate(-3327.457 -2049.108)"
              fill="none"
              stroke="#2e323c"
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="1.3"
            />
            <path
              id="Caminho_3118"
              d="M3368.923,2090.078v.66a5.405,5.405,0,0,1-5.407,5.4h0a5.4,5.4,0,0,1-5.4-5.4v-.66"
              transform="translate(-3354.612 -2049.108)"
              fill="none"
              stroke="#2e323c"
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="1.3"
            />
            <path
              id="Caminho_3119"
              d="M3410.61,2090.078h-49.835"
              transform="translate(-3351.52 -2049.108)"
              fill="none"
              stroke="#2e323c"
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="1.3"
            />
            <line
              id="Linha_36"
              x2="8.512"
              transform="translate(29.918 34.464)"
              fill="none"
              stroke="#2e323c"
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="1.3"
            />
          </g>
        </g>
        <path
          id="Caminho_17627"
          d="M3398.464,2073.177H3368.4a1.68,1.68,0,0,0-1.157.47l-4.524,8.672h41.422l-4.522-8.672A1.675,1.675,0,0,0,3398.464,2073.177Z"
          transform="translate(-3349.149 -2068.733)"
          fill={fill}
        />
      </g>
    </g>
  </svg>
);

export default CarAlert;
