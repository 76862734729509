import React from "react";

const Location = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="20"
    height="20"
    viewBox="0 0 20 20"
  >
    <defs>
      <clipPath id="clip-_3x_-_Pin">
        <rect width="20" height="20" />
      </clipPath>
    </defs>
    <g id="_3x_-_Pin" clipPath="url(#clip-_3x_-_Pin)">
      <g id="Grupo_5" transform="translate(-657.311 -532.293)">
        <path
          id="Caminho_37793"
          d="M296.836,280.589a3.042,3.042,0,1,0,3.042,3.043A3.046,3.046,0,0,0,296.836,280.589Zm0,4.7a1.653,1.653,0,1,1,1.653-1.653A1.655,1.655,0,0,1,296.837,285.285Z"
          transform="translate(370.264 255.073)"
          fill="#1e2126"
        />
        <path
          id="Caminho_37794"
          d="M296.72,277.119a6.8,6.8,0,0,0-6.785,6.788c0,2.009,1.85,4.989,3.486,7.234-1.721.576-2.738,1.584-2.738,2.738,0,1.847,2.6,3.24,6.038,3.24s6.037-1.393,6.037-3.24c0-1.152-1.015-2.161-2.738-2.738,1.637-2.247,3.486-5.228,3.486-7.234A6.8,6.8,0,0,0,296.72,277.119Zm4.644,16.76c0,.379-.422.813-1.138,1.159l.1.218-.115-.216a9.215,9.215,0,0,1-6.974.008c-.731-.348-1.168-.786-1.168-1.172,0-.582.894-1.2,2.242-1.55.47.6.962,1.213,1.463,1.808a1.23,1.23,0,0,0,1.732.148,1.107,1.107,0,0,0,.147-.148c.343-.4.868-1.038,1.464-1.809C300.6,292.716,301.364,293.393,301.364,293.879Zm-1.463-5.008a49.415,49.415,0,0,1-3.181,4.223,50.058,50.058,0,0,1-3.2-4.253c-1.814-2.743-2.194-4.2-2.194-4.934a5.394,5.394,0,0,1,10.788,0C302.115,284.645,301.731,286.1,299.9,288.871Z"
          transform="translate(370.376 255.174)"
          fill="#1e2126"
        />
      </g>
    </g>
  </svg>
);

export default Location;
