import React from "react";

const Trash = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="15"
    height="15"
    viewBox="0 0 15 15"
  >
    <defs>
      <clipPath id="clip-_2x_-_Trash">
        <rect width="15" height="15" />
      </clipPath>
    </defs>
    <g id="_2x_-_Trash" clipPath="url(#clip-_2x_-_Trash)">
      <path
        id="Caminho_13"
        d="M365.317,335.111h-3.059v-.334a1.069,1.069,0,0,0-1.067-1.067h-5.484a1.069,1.069,0,0,0-1.067,1.067v.334h-3.126a.6.6,0,1,0,0,1.2h1.467v9.86a1.541,1.541,0,0,0,1.538,1.538h7.862a1.541,1.541,0,0,0,1.537-1.538v-9.86h1.4a.6.6,0,0,0,0-1.2Zm-9.48-.2h5.226v.2h-5.226Zm6.884,11.262a.343.343,0,0,1-.341.341h-7.86a.345.345,0,0,1-.342-.341v-9.825h8.543Z"
        transform="translate(-350.915 -332.71)"
        fill="#1e2126"
      />
    </g>
  </svg>
);

export default Trash;
