import React from "react";

const Check = ({ color, width, height }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={width}
    height={height}
    viewBox="0 0 10 10"
  >
    <defs>
      <clipPath id="clip-_1x_Check">
        <rect width="10" height="10" />
      </clipPath>
    </defs>
    <g clipPath="url(#clip-_1x_Check)">
      <g transform="translate(-653.157 -388.22)">
        <path
          d="M661.246,391.079h0a.536.536,0,0,0-.726,0l-3.492,3.493-.964-.963a.53.53,0,0,0-.371-.144.523.523,0,0,0-.364.89l1.329,1.329a.523.523,0,0,0,.369.151.536.536,0,0,0,.372-.152l3.864-3.866a.506.506,0,0,0,.145-.373A.524.524,0,0,0,661.246,391.079Z"
          fill={color}
        />
      </g>
    </g>
  </svg>
);

export default Check;
