import React from "react";

const Info = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="15"
    height="15"
    viewBox="0 0 15 15"
  >
    <defs>
      <clipPath id="clip-_2x_Info">
        <rect width="15" height="15" />
      </clipPath>
    </defs>
    <g id="_2x_Info" clipPath="url(#clip-_2x_Info)">
      <g id="Grupo_22" transform="translate(-1682 -658.311)">
        <path
          id="Caminho_37868"
          d="M719.045,333.236a7.5,7.5,0,1,0,7.5,7.5A7.524,7.524,0,0,0,719.045,333.236Zm6.357,7.5a6.357,6.357,0,1,1-6.357-6.356A6.373,6.373,0,0,1,725.4,340.735Z"
          transform="translate(970.455 325.075)"
          fill="#1e2126"
        />
        <g id="Grupo_25975" transform="translate(1688.774 662.737)">
          <path
            id="Caminho_37732"
            d="M719.276,344.033a.559.559,0,0,1-.558-.558v-2.795a.559.559,0,0,1,.558-.559h0a.559.559,0,0,1,.559.558h0v2.795A.559.559,0,0,1,719.276,344.033Z"
            transform="translate(-718.718 -337.884)"
            fill="#1e2126"
          />
          <circle
            id="Elipse_1889"
            cx="0.559"
            cy="0.559"
            r="0.559"
            transform="translate(0 0)"
            fill="#1e2126"
          />
        </g>
      </g>
    </g>
  </svg>
);

export default Info;
