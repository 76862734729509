import React from "react";

const Curve = (props) => {
  const { className, color, width = "86.372", height = "86.546" } = props;
  return (
    <div className={className}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width={width}
        height={height}
        viewBox="0 0 86.372 86.546"
        fill={color}
      >
        <path
          d="M1003.337,721.2h-36.1a42.66,42.66,0,0,0-42.468-42.663v-36.1A78.77,78.77,0,0,1,1003.337,721.2Z"
          transform="matrix(-0.995, -0.105, 0.105, -0.995, 930.687, 822.127)"
        />
      </svg>
    </div>
  );
};

export default Curve;
